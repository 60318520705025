<template>
  <div
    class="index-banner agent"
    :style="{'background-image':'url(assets/icon/banner-bg.png)','background-size': '46% 35rem','background-repeat': 'no-repeat', 'background-position': 'right 0px'}"
  >
    <div class="container">
      <div class="row">
        <div class="col-md-1"></div>
        <div class="col-md-9 mb-5">
          <div class="banner-form section-title py-4">
            <h3 class="text-center title text-primary">
              <u>Become A Partner</u>
            </h3>
            <div class="p-4">
              <form method="POST" action="javascript:;" class="tab-content">
                <!--                                company details-->
                <ul class="nav nav-tabs mt-3">
                  <li class="nav-item">
                    <a class="nav-link active">Company Details</a>
                  </li>
                </ul>
                <div class="row">
                  <div class="form-group col-md-6">
                    <label for="name">Company Name</label>
                    <input
                      type="text"
                      class="form-control"
                      name="name"
                      id="name"
                      placeholder="Softechpark"
                    />
                  </div>
                  <div class="form-group col-md-6">
                    <label for="email">Company Email</label>
                    <input
                      type="text"
                      class="form-control"
                      id="email"
                      name="email"
                      placeholder="info@softechpark.com"
                    />
                  </div>
                  <div class="form-group col-md-6">
                    <label for="country">Country</label>
                    <select name="country" id="country" class="form-control">
                      <option value="nepali">Nepali</option>
                    </select>
                  </div>
                  <div class="form-group col-md-6">
                    <label for="patnership">Partnership Behalf</label>
                    <select name="patnership" id="patnership" class="form-control">
                      <option value="self">Self</option>
                    </select>
                  </div>
                  <div class="form-group col-md-6">
                    <label for="state">State</label>
                    <select name="state" id="state" class="form-control">
                      <option value="1">Province 1</option>
                      <option value="2">Province 2</option>
                      <option value="3">Province 3</option>
                      <option value="4">Province 4</option>
                      <option value="5">Province 5</option>
                      <option value="6">Province 6</option>
                      <option value="7">Province 7</option>
                    </select>
                  </div>
                  <div class="form-group col-md-6">
                    <label for="website">Company Website</label>
                    <input
                      type="text"
                      class="form-control"
                      id="website"
                      name="website"
                      placeholder="www.abcd.com.np"
                    />
                  </div>
                  <div class="form-group col-md-6">
                    <label for="city">City</label>
                    <input
                      type="text"
                      class="form-control"
                      id="city"
                      name="city"
                      placeholder="Kathmandu"
                    />
                  </div>
                  <div class="form-group col-md-6">
                    <label for="telephone">Telephone</label>
                    <input
                      type="text"
                      class="form-control"
                      id="telephone"
                      name="telephone"
                      placeholder="01-42xxxxx"
                    />
                  </div>
                  <div class="form-group col-md-6">
                    <label for="address">Office Address</label>
                    <input
                      type="text"
                      class="form-control"
                      id="address"
                      name="address"
                      placeholder="Tinkune"
                    />
                  </div>
                  <div class="form-group col-md-6">
                    <label for="established">Established Date</label>
                    <input
                      type="text"
                      class="form-control"
                      id="established"
                      name="established"
                      placeholder="1995"
                    />
                  </div>
                  <div class="form-group col-md-6">
                    <label for="pan">PAN/VAT</label>
                    <input
                      type="number"
                      class="form-control"
                      id="pan"
                      name="pan"
                      placeholder="number"
                    />
                  </div>
                  <div class="form-group col-md-6">
                    <label for="type">Form Of Business</label>
                    <select name="type" id="type" class="form-control">
                      <option value="1">Private Limited</option>
                    </select>
                  </div>
                </div>
                <!--                                personal details-->
                <ul class="nav nav-tabs mt-3">
                  <li class="nav-item">
                    <a class="nav-link active">Personal Detail Of Reseller/Partner</a>
                  </li>
                </ul>
                <div class="row">
                  <div class="form-group col-md-6">
                    <label for="full_name">Full Name</label>
                    <input
                      type="text"
                      class="form-control"
                      name="name"
                      id="full_name"
                      placeholder="Softechpark"
                    />
                  </div>
                  <div class="form-group col-md-6">
                    <label for="citizenship">Citizenship Number</label>
                    <input
                      type="text"
                      class="form-control"
                      id="citizenship"
                      name="citizenship"
                      placeholder="204265/2003"
                    />
                  </div>
                  <div class="form-group col-md-6">
                    <label for="nationality">Nationality</label>
                    <select name="nationality" id="nationality" class="form-control">
                      <option value="nepali">Nepali</option>
                    </select>
                  </div>
                  <div class="form-group col-md-6">
                    <label for="passport">Passport Number</label>
                    <input
                      type="text"
                      class="form-control"
                      id="passport"
                      name="passport"
                      placeholder="2042655"
                    />
                  </div>
                  <div class="form-group col-md-6">
                    <label for="t_address">Temporary Address</label>
                    <input
                      type="text"
                      class="form-control"
                      id="t_address"
                      name="t_address"
                      placeholder="Tinkune, Kathmandu"
                    />
                  </div>
                  <div class="form-group col-md-6 mr-0 pr-0">
                    <label for="marital_status">Marital Status</label>
                    <br />
                    <input type="radio" id="unmarried" name="marital_status" value="0" />
                    <label for="unmarried">Un Married</label>
                    <input type="radio" id="married" name="marital_status" value="1" />
                    <label for="married">Married</label>
                    <input type="radio" id="divorced" name="marital_status" value="2" />
                    <label for="divorced">Divorced</label>
                    <input type="radio" id="widowed" name="marital_status" value="3" />
                    <label for="widowed">Widowed</label>
                  </div>
                  <div class="form-group col-md-6">
                    <label for="p_address">Permanent Address</label>
                    <input
                      type="text"
                      class="form-control"
                      id="p_address"
                      name="p_address"
                      placeholder="Tinkune, Kathmandu"
                    />
                  </div>
                  <div class="form-group col-md-6">
                    <label for="father">Father's Name</label>
                    <input
                      type="text"
                      class="form-control"
                      id="father"
                      name="father"
                      placeholder="Father Name"
                    />
                  </div>
                  <div class="form-group col-md-6">
                    <label for="email">Email</label>
                    <input
                      type="email"
                      class="form-control"
                      id="email"
                      name="email"
                      placeholder="info@softechpark.com"
                    />
                  </div>
                  <div class="form-group col-md-6">
                    <label for="father_contact">Father's Contact Number</label>
                    <input
                      type="number"
                      class="form-control"
                      id="father_contact"
                      name="father_contact"
                      placeholder="9840xxxxxx"
                    />
                  </div>
                  <div class="form-group col-md-6">
                    <label for="dob">D.O.B.</label>
                    <input
                      type="text"
                      class="form-control"
                      id="dob"
                      name="dob"
                      placeholder="2000/01/25"
                    />
                  </div>
                  <div class="form-group col-md-6">
                    <label for="mother">Mother's Name</label>
                    <input
                      type="text"
                      class="form-control"
                      id="mother"
                      name="mother"
                      placeholder="Mother Name"
                    />
                  </div>
                  <div class="form-group col-md-6 mr-0 pr-0">
                    <label for="gender">Gender</label>
                    <br />
                    <input type="radio" id="male" name="gender" value="male" />
                    <label for="male">Male</label>
                    <input type="radio" id="female" name="gender" value="female" />
                    <label for="female">Female</label>
                    <input type="radio" id="other" name="gender" value="other" />
                    <label for="other">Other</label>
                  </div>
                  <div class="form-group col-md-6">
                    <label for="mother_contact">Mother's Contact Number</label>
                    <input
                      type="number"
                      class="form-control"
                      id="mother_contact"
                      name="mother_contact"
                      placeholder="9840xxxxxx"
                    />
                  </div>
                </div>
                <!--                                Nominee details-->
                <ul class="nav nav-tabs mt-3">
                  <li class="nav-item">
                    <a class="nav-link active">Nominee Detail</a>
                  </li>
                </ul>
                <div class="row">
                  <div class="form-group col-md-6">
                    <label for="nominee_name">Full Name</label>
                    <input
                      type="text"
                      class="form-control"
                      name="nominee_name"
                      id="nominee_name"
                      placeholder="Nominee Name"
                    />
                  </div>
                  <div class="form-group col-md-6">
                    <label for="nominee_telephone">Telephone</label>
                    <input
                      type="text"
                      class="form-control"
                      id="nominee_telephone"
                      name="nominee_telephone"
                      placeholder="01-42xxxxx"
                    />
                  </div>
                  <div class="form-group col-md-6">
                    <label for="relationship">Relationship to me</label>
                    <input
                      type="text"
                      class="form-control"
                      name="relationship"
                      id="relationship"
                      placeholder="Nepalese"
                    />
                  </div>
                  <div class="form-group col-md-6">
                    <label for="mobile">Mobile Number</label>
                    <input
                      type="number"
                      class="form-control"
                      id="mobile"
                      name="mobile"
                      placeholder="9840xxxxxx"
                    />
                  </div>
                  <div class="form-group col-md-6">
                    <label for="dob">D.O.B.</label>
                    <input
                      type="text"
                      class="form-control"
                      id="dob"
                      name="dob"
                      placeholder="1995/10/30"
                    />
                  </div>
                  <div class="form-group col-md-6">
                    <label for="email">Email</label>
                    <input
                      type="text"
                      class="form-control"
                      id="email"
                      name="email"
                      placeholder="info@gmail.com"
                    />
                  </div>
                  <div class="form-group col-md-6">
                    <label for="citizenship">Citizenship Number</label>
                    <input
                      type="text"
                      class="form-control"
                      id="citizenship"
                      name="citizenship"
                      placeholder="425265/2041"
                    />
                  </div>
                </div>
                <!--                                bank details-->
                <ul class="nav nav-tabs mt-3">
                  <li class="nav-item">
                    <a class="nav-link active">Bank Detail</a>
                  </li>
                </ul>
                <div class="row">
                  <div class="form-group col-md-6">
                    <label for="bank">Bank Name</label>
                    <input
                      type="text"
                      class="form-control"
                      name="bank"
                      id="bank"
                      placeholder="Janata Bank"
                    />
                  </div>
                  <div class="form-group col-md-6">
                    <label for="account">A/C Number</label>
                    <input
                      type="number"
                      class="form-control"
                      id="account"
                      name="account"
                      placeholder="22015241410591"
                    />
                  </div>
                  <div class="form-group col-md-6">
                    <label for="holder">A/C Holder Name</label>
                    <input
                      type="text"
                      class="form-control"
                      name="holder"
                      id="holder"
                      placeholder="Account Holder Name"
                    />
                  </div>
                  <div class="form-group col-md-6">
                    <label for="branch">Issued Branch</label>
                    <input
                      type="text"
                      class="form-control"
                      id="branch"
                      name="branch"
                      placeholder="Tinkune"
                    />
                  </div>
                </div>
                <!--                                company partner details-->
                <ul class="nav nav-tabs mt-3">
                  <li class="nav-item">
                    <a class="nav-link active">Company Partner Detail (If Any)</a>
                  </li>
                </ul>
                <div class="row">
                  <div class="form-group col-md-6">
                    <label for="full_name">Full Name</label>
                    <input
                      type="text"
                      class="form-control"
                      name="name"
                      id="full_name"
                      placeholder="Softechpark"
                    />
                  </div>
                  <div class="form-group col-md-6">
                    <label for="citizenship">Citizenship Number</label>
                    <input
                      type="text"
                      class="form-control"
                      id="citizenship"
                      name="citizenship"
                      placeholder="204265/2003"
                    />
                  </div>
                  <div class="form-group col-md-6">
                    <label for="nationality">Nationality</label>
                    <select name="nationality" id="nationality" class="form-control">
                      <option value="nepali">Nepali</option>
                    </select>
                  </div>
                  <div class="form-group col-md-6">
                    <label for="passport">Passport Number</label>
                    <input
                      type="text"
                      class="form-control"
                      id="passport"
                      name="passport"
                      placeholder="2042655"
                    />
                  </div>
                  <div class="form-group col-md-6">
                    <label for="t_address">Temporary Address</label>
                    <input
                      type="text"
                      class="form-control"
                      id="t_address"
                      name="t_address"
                      placeholder="Tinkune, Kathmandu"
                    />
                  </div>
                  <div class="form-group col-md-6 mr-0 pr-0">
                    <label for="marital_status">Marital Status</label>
                    <br />
                    <input type="radio" id="unmarried" name="marital_status" value="0" />
                    <label for="unmarried">Un Married</label>
                    <input type="radio" id="married" name="marital_status" value="1" />
                    <label for="married">Married</label>
                    <input type="radio" id="divorced" name="marital_status" value="2" />
                    <label for="divorced">Divorced</label>
                    <input type="radio" id="widowed" name="marital_status" value="3" />
                    <label for="widowed">Widowed</label>
                  </div>
                  <div class="form-group col-md-6">
                    <label for="p_address">Permanent Address</label>
                    <input
                      type="text"
                      class="form-control"
                      id="p_address"
                      name="p_address"
                      placeholder="Tinkune, Kathmandu"
                    />
                  </div>
                  <div class="form-group col-md-6">
                    <label for="father">Father's Name</label>
                    <input
                      type="text"
                      class="form-control"
                      id="father"
                      name="father"
                      placeholder="Father Name"
                    />
                  </div>
                  <div class="form-group col-md-6">
                    <label for="email">Email</label>
                    <input
                      type="email"
                      class="form-control"
                      id="email"
                      name="email"
                      placeholder="info@softechpark.com"
                    />
                  </div>
                  <div class="form-group col-md-6">
                    <label for="father_contact">Father's Contact Number</label>
                    <input
                      type="number"
                      class="form-control"
                      id="father_contact"
                      name="father_contact"
                      placeholder="9840xxxxxx"
                    />
                  </div>
                  <div class="form-group col-md-6">
                    <label for="dob">D.O.B.</label>
                    <input
                      type="text"
                      class="form-control"
                      id="dob"
                      name="dob"
                      placeholder="2000/01/25"
                    />
                  </div>
                  <div class="form-group col-md-6">
                    <label for="mother">Mother's Name</label>
                    <input
                      type="text"
                      class="form-control"
                      id="mother"
                      name="mother"
                      placeholder="Mother Name"
                    />
                  </div>
                  <div class="form-group col-md-6 mr-0 pr-0">
                    <label for="gender">Gender</label>
                    <br />
                    <input type="radio" id="male" name="gender" value="male" />
                    <label for="male">Male</label>
                    <input type="radio" id="female" name="gender" value="female" />
                    <label for="female">Female</label>
                    <input type="radio" id="other" name="gender" value="other" />
                    <label for="other">Other</label>
                  </div>
                  <div class="form-group col-md-6">
                    <label for="mother_contact">Mother's Contact Number</label>
                    <input
                      type="number"
                      class="form-control"
                      id="mother_contact"
                      name="mother_contact"
                      placeholder="9840xxxxxx"
                    />
                  </div>
                </div>
                <!--                                document details-->
                <ul class="nav nav-tabs mt-3">
                  <li class="nav-item">
                    <a class="nav-link active">Documents</a>
                  </li>
                </ul>
                <div class="row">
                  <div class="col-md-6">
                    <label>Citizenship Copy</label>
                    <div class="input-group">
                      <input type="file" name="citizen1" id="citizen1" style="display: none;" />
                      <input type="text" class="form-control" placeholder="Front" readonly />
                      <div class="input-group-append" style="    background: #1451C0;height: 35px;">
                        <label
                          for="citizen1"
                          style="color: white; padding: 0.35rem 1rem 0.2rem 1rem;margin: 0;"
                        >Browse</label>
                      </div>
                    </div>
                  </div>
                  <div class="col-md-6">
                    <div class="input-group" style="margin-top: 1.8rem;">
                      <input type="file" name="citizen2" id="citizen2" style="display: none;" />
                      <input type="text" class="form-control" placeholder="Back" readonly />
                      <div class="input-group-append" style="    background: #1451C0;height: 35px;">
                        <label
                          for="citizen2"
                          style="color: white; padding: 0.35rem 1rem 0.2rem 1rem;margin: 0;"
                        >Browse</label>
                      </div>
                    </div>
                  </div>
                  <div class="col-md-6">
                    <label>PAN/VAT Certificates</label>
                    <div class="input-group">
                      <input type="file" name="pan" id="pan" style="display: none;" />
                      <input type="text" class="form-control" placeholder="Front" readonly />
                      <div class="input-group-append" style="    background: #1451C0;height: 35px;">
                        <label
                          for="pan"
                          style="color: white; padding: 0.35rem 1rem 0.2rem 1rem;margin: 0;"
                        >Browse</label>
                      </div>
                    </div>
                  </div>
                  <div class="col-md-6"></div>
                  <div class="col-md-6">
                    <label>Personal Photo</label>
                    <div class="input-group">
                      <input type="file" name="photo" id="photo" style="display: none;" />
                      <input type="text" class="form-control" placeholder="Owner's" readonly />
                      <div class="input-group-append" style="    background: #1451C0;height: 35px;">
                        <label
                          for="photo"
                          style="color: white; padding: 0.35rem 1rem 0.2rem 1rem;margin: 0;"
                        >Browse</label>
                      </div>
                    </div>
                  </div>
                  <div class="col-md-6"></div>
                  <div class="col-md-6">
                    <label>Others</label>
                    <div class="input-group">
                      <input type="file" name="other_doc" id="other_doc" style="display: none;" />
                      <input type="text" class="form-control" placeholder="Other Document" readonly />
                      <div class="input-group-append" style="    background: #1451C0;height: 35px;">
                        <label
                          for="other_doc"
                          style="color: white; padding: 0.35rem 1rem 0.2rem 1rem;margin: 0;"
                        >Browse</label>
                      </div>
                    </div>
                  </div>
                  <div class="col-md-12">Notes: you can upload only pdf or jpg file in here.</div>
                  <div class="col-md-12 text-right">
                    <button class="btn btn-success border-radius-0">Submit</button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
        <div class="col-md-2">
          <img
            src="assets/icon/mascot.png"
            class="img-fluid banner-image-fluid"
            style="max-width: 156%;"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "become-partner",
};
</script>

<style scoped>
.agent .nav-tabs {
  border-bottom: 2px solid #1451c0 !important;
}
.agent .nav-link {
  padding: 0rem 1rem;
}
.agent .modal_with_tab .nav-tabs .nav-item.show .nav-link,
.nav-tabs .nav-link.active {
  border-radius: 0 15px 0 0;
}
.agent .form-group {
  margin-bottom: 0rem;
}
</style>